import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Button, Input, Table, TableColumn, Divider, Row, Col, Upload, Tabs, TabPane, Empty } from 'element-ui';

Vue.use(Button)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Divider)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Empty)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
