import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 引入所有的模块并获取到文件名
const files = require.context("./routes", false, /\.js$/);
const _routes = [];
// 对文件进行遍历
files.keys().forEach((key) => {
  // 获取文件中的内容，并添加到事先创建好的数组中
  _routes.push(files(key).default);
});

const routes = [
  ..._routes,
];

const router = new VueRouter({
  routes: [
    ...routes,
  ],
});

export default router;
